<template>
  <div class="columns">
    <div class="column is-12">
      <v-main>
        <v-container fluid>

          <div class="back-button text-right mb-3">
            <v-btn icon @click="goBack">
              <v-icon color="grey darken-2">mdi-chevron-down</v-icon>
            </v-btn>
          </div>

          <v-container v-if="active_orders_transporter && active_orders_transporter.length === 0">
            <v-row justify="center" align="center">
              <v-col cols="12" md="8">
                <div class="text-center">
                  <h2 class="title is-3 mb-2">У вас пока нет заказов</h2>
                  <b-button @click="$router.push('/orders/search')" type="is-success is-light">
                    Найти заказ
                  </b-button>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <v-container v-else>
            <v-row v-for="(item, index) in active_orders_transporter" :key="item.id">
<!--              @click="goToOrder(item.id)"-->
              <v-card :variant="variant" :class="'w-100 mb-3 no-gradient-block-' + (index % 4 + 1)">
                  <v-card-text class="pb-0">
                      <div class="pa-3 pb-0">
                          <v-row>
                            <v-col class="pa-0">
                              <h1 class="title is-6 ml-3">{{  dateText(item.time) }}</h1>
                            </v-col>
                            <b-taglist>
                              <b-tag rounded v-if="item.status === 'created'" type="is-primary">Создан</b-tag>
                              <b-tag rounded v-else-if="item.status === 'agreed'" type="is-info">Подтвержден</b-tag>
                              <b-tag rounded v-else-if="item.status === 'completed'" type="is-success">Доставлен</b-tag>
                              <b-tag rounded v-else-if="item.status === 'in_progress'" type="is-warning">В пути</b-tag>
                              <b-tag rounded v-else-if="item.status === 'cancelled'" type="is-danger">Отменен</b-tag>
                            </b-taglist>
                          </v-row>
                          <v-row class="pt-2">
                              <v-col cols="2" class="column is-3">
                                  <img src="/assets/road.svg">
                              </v-col>
                              <v-col cols="10" class="column is-9 order-route">
                                  <v-row>
                                      <h1 class="title is-5">{{  item.from_location }}</h1>
                                  </v-row>
                                  <v-row>
                                      <h1 class="title is-5 mt-2">{{  item.to_location }}</h1>
                                  </v-row>
                              </v-col>
                          </v-row>

                        <div @click="toggleCollapse(index)" class="pt-5" style="cursor: pointer">
                          <span>Дополнительная информация</span>
                          <v-icon>{{ showDetails[index] ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
                        </div>

                        <!-- Блок с дополнительной информацией, который показывается/скрывается по клику на кнопку -->
                        <div v-if="showDetails[index]" class="pt-2">

                          <h3 class="title is-6 pt-3">Информация пользователя</h3>

                          <div v-if="item.type_vehicle">
                            <h3 class="title is-6">Тип транспорта</h3>
                            <p>{{ wrapperTransportType(item.type_vehicle) }}</p>
                          </div>

                          <div v-if="item.car_id">
                            <h3 class="title is-6">Транспорт</h3>
                            <p>{{ wrapperCar(item) }}</p>
                          </div>

                          <h3 class="title is-6">Комментарий</h3>
                          <p class="mb-0 text-pre-wrap">{{item.comment}}</p>

                          <h3 class="title is-6 pt-6">Ваша информация</h3>

<!--                          <h3 class="title is-6">Марка и модель</h3>-->
<!--                          <p>{{ item.transporter_data.brand  + ' ' + item.transporter_data.model}}</p>-->

<!--                          <h3 class="title is-6">Номер</h3>-->
<!--                          <p class="mb-0">{{ item.transporter_data.transport_number }}</p>-->

                          <h3 class="title is-6">Цена</h3>
                          <p class="mb-0">{{ item.offer.price }}</p>

                          <h3 class="title is-6">Комментарий</h3>
                          <p class="mb-0 text-pre-wrap">{{ item.offer.comment }}</p>

                        </div>
                      </div>
                  </v-card-text>


<!--                in_progress - Заказ в процессе выполнения (в пути)-->
<!--                completed - Заказ выполнен (доставлен)-->
<!--                cancelled - Заказ отменен-->

                <v-card-actions class="pb-6 pt-3 flex justify-center">
                  <v-col justify="center">

                    <v-row justify="center" class="mb-3">
                      <b-field class="mt-3">

                        <b-radio-button
                          v-model="item.status"
                          :native-value="'agreed'"
                          @input="changeStatus(item.id, 'agreed')"
                        >
                          <span>Согласован</span>
                        </b-radio-button>

                        <b-radio-button
                          v-model="item.status"
                          :native-value="'in_progress'"
                          @input="changeStatus(item.id, 'in_progress')"
                        >
                          <span>В пути</span>
                        </b-radio-button>

                        <b-radio-button
                          v-model="item.status"
                          :native-value="'completed'"
                          @input="changeStatus(item.id, 'completed')"
                        >
                          <span>Довезён</span>
                        </b-radio-button>
                      </b-field>
                    </v-row>

<!--                    <v-row>-->
<!--                      <v-col cols="12" class="py-0 text-center">-->
<!--                        <b-button-->
<!--                          icon-left="map"-->
<!--                          @click="openYandexMaps(item.from_location, item.to_location)" type="is-text">-->
<!--                          Проложить маршрут-->
<!--                        </b-button>-->
<!--                      </v-col>-->
<!--                    </v-row>-->

<!--                    <v-row>-->
<!--                      <v-col cols="12" class="py-0 text-center">-->
<!--                        <b-button-->
<!--                          icon-left="map"-->
<!--                          @click="openYandexMaps2(item.from_location, item.to_location)" type="is-text">-->
<!--                          Проложить маршрут 2-->
<!--                        </b-button>-->
<!--                      </v-col>-->
<!--                    </v-row>-->

                    <v-row>
                      <v-col cols="6" class="py-0 text-center">
                        <b-button
                          class="is-danger"
                          @click="changeStatus(item.id, 'cancelled')"
                          type="is-text">
                          Отменить
                        </b-button>
                      </v-col>
                      <v-col cols="6" class="py-0 text-center">
                        <b-button
                          @click="goToContactsPartner(item.id)"
                          class="is-info"
                          type="is-text">
                          Связаться
                        </b-button>
                      </v-col>
                    </v-row>

                  </v-col>
                </v-card-actions>

              </v-card>
            </v-row>
          </v-container>


        </v-container>
      </v-main>
    </div>

    <FullScreenLoader :show="isLoading" />

  </div>

</template>

<script>

export default {
  name: 'ActiveOrderListTransporter',
  data() {
    return {
      isLoading: false,
      test: '1',
      variant: 'outlined',
      showDetails: [],
      types: {
        0: {
          text: 'Мотоцикл'
        },
        1: {
          text: 'Автомобиль'
        },
        2: {
          text: 'Грузовик'
        },
        3: {
          text: 'Неформат'
        }
      },
      blocks: [
        { text: 'Мотоцикл', image: '/assets/scooter.svg' },
        { text: 'Автомобиль', image: '/assets/car.svg' },
        { text: 'Грузовик', image: '/assets/big-car.svg' },
        { text: 'Спецтехника', image: '/assets/special_equipment.svg' }
      ],
    };
  },
  created() {
    this.$store.dispatch('GET_ACTIVE_ORDERS_TRANSPORTER');
  },
  computed: {
    active_orders_transporter() {
      console.log('Активные заказы (перевозчик): ');
      console.log(this.$store.getters.ACTIVE_ORDERS_TRANSPORTER);
      return this.$store.getters.ACTIVE_ORDERS_TRANSPORTER;
    },
  },
  methods: {
    // openYandexMaps(start_point, end_point) {
    //   start_point = { lat: 55.751244, lng: 37.618423 };
    //   end_point = { lat: 59.934280, lng: 30.335099 };
    //
    //   const yandexUrl = `https://yandex.ru/maps/?rtext=${encodeURIComponent(start_point.lat + ',' + start_point.lng)}~${encodeURIComponent(end_point.lat + ',' + end_point.lng)}&rtt=auto`;
    //
    //   console.log('Yandex URL: ', yandexUrl);
    //
    //   // const yandexUrl = `https://yandex.ru/maps/?rtext=${encodeURIComponent(start_point)}~${encodeURIComponent(end_point)}&rtt=auto`;
    //
    //   window.open(yandexUrl, '_blank');
    // },
    // openYandexMaps2(start_point, end_point) {
    //   const yandexUrl = `https://yandex.ru/maps/?rtext=${encodeURIComponent(start_point)}~${encodeURIComponent(end_point)}&rtt=auto`;
    //   console.log('Yandex URL: ', yandexUrl);
    //   window.open(yandexUrl, '_blank');
    // },
    wrapperTransportType(type_vehicle) {
      if (type_vehicle) {
        return this.blocks[type_vehicle].text
      } else {
        return '';
      }
    },
    wrapperCar(item) {
      if (item && item.car.mark && item.car.model) {
        return item.car.mark + ' ' + item.car.model
      }
      return '';
    },
    changeStatus(id, status) {
      let data = {
        order_id: id,
        status: status
      }

      if (status === 'cancelled') {
        this.$store.commit('SET_CANCEL_DATA', data);
        this.$router.push('/cancellation-of-order-transporter/');
      } else {
        this.isLoading = true;
        this.$store.dispatch('CHANGE_STATUS', data).then(() => {
          this.$store.dispatch('GET_ACTIVE_ORDERS_TRANSPORTER');
          this.$buefy.toast.open({
            message: 'Статус изменен',
            type: 'is-success'
          });
        }).catch(() => {
          this.$buefy.toast.open({
            message: 'Ошибка смены статуса',
            type: 'is-danger'
          })
        }).finally(() => {
          this.isLoading = false;
        });
      }
    },
    dateText(date) {
      return this.$store.getters.TEXT_DATE(date);
    },
    toggleCollapse(index) {
      // Изменяем состояние сворачивания для соответствующей карточки
      this.$set(this.showDetails, index, !this.showDetails[index]);
    },
    goBack() {
      this.$router.push('/activity-feed');
    },
    goToContactsPartner(id) {
      this.$router.push('/order/contacts-partner/' + id);
    },
  },
};
</script>

<style scoped>
.title.is-6 {
  font-size: 1.2em !important;
  margin-bottom: 0 !important;
}

.is-light {
  background-color: #f5f5f5; /* Цвет фона для невыбранных кнопок */
  color: #333; /* Цвет текста для невыбранных кнопок */
  border: 1px solid #ccc; /* Цвет границы для невыбранных кнопок */
}
</style>
